import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const services = [
  { 
    id: 1, 
    title: 'Web Development', 
    description: `We offer top-notch web design and development services tailored to meet your business needs. Our team of expert developers focuses on creating modern, fast, and responsive websites that perform well across all devices.

    Key Features:
    - Responsive Design: Optimal performance across all devices, including mobile, tablet, and desktop.
    - SEO Optimization: On-page SEO techniques to boost visibility on search engines.
    - Performance Optimization: Fast loading times and efficient coding practices.
    - E-Commerce Integration: Secure, user-friendly e-commerce platforms.
    - Data Security: SSL encryption and best practices for secure transactions and user data protection.

    Technologies Used:
    - Frontend: HTML5, CSS3, JavaScript (React, Vue.js, Angular)
    - Backend: Node.js, PHP, Python (Django)
    - Databases: MySQL, PostgreSQL, MongoDB
    - DevOps: Docker, AWS, Heroku`,
    img: '/Website.jpg'
  },
  { 
    id: 2, 
    title: 'Mobile App Development', 
    description: `Our mobile app development services are designed to deliver cutting-edge apps that cater to your business needs. Whether you need a native or cross-platform solution, we ensure smooth user experiences.

    Key Features:
    - Native and Cross-Platform Development: Native iOS, Android, and cross-platform frameworks like React Native and Flutter.
    - User-Centric Design: Intuitive and visually appealing interfaces.
    - API Integrations: Seamless integration with third-party APIs.
    - Offline Functionality: Offline support and synchronization.
    - Security: High standards in app security, including user authentication and secure data storage.

    Technologies Used:
    - iOS: Swift, Objective-C
    - Android: Kotlin, Java
    - Cross-Platform: React Native, Flutter
    - Backend: Firebase, Node.js, Ruby on Rails`,
    img: '/MBD.png'
  },
  { 
    id: 3, 
    title: 'Custom Software Development', 
    description: `We provide custom software solutions to address unique business challenges. From enterprise-level systems to specialized applications, we design and build software that automates processes, enhances efficiency, and drives innovation.

    Key Features:
    - Tailored Solutions: Custom-built software designed to fit your specific business needs.
    - Scalability: Built with future growth in mind, allowing for easy scaling.
    - Cloud Integration: Integrate cloud platforms like AWS, Google Cloud, or Azure.
    - Multi-Platform Compatibility: Software that works across web, desktop, or mobile.
    - Continuous Support: Ongoing maintenance and support.

    Technologies Used:
    - Programming Languages: Java, C#, Python
    - Frameworks: ASP.NET, Spring Boot, Django
    - Databases: Oracle, MySQL, MongoDB
    - Cloud: AWS, Google Cloud, Microsoft Azure`,
    img: '/SDD.png'
  },
  { 
    id: 4, 
    title: 'SEO Optimization', 
    description: `Boost your website’s visibility and drive organic traffic through our comprehensive SEO services. Our strategies help improve your rankings on search engines.

    Key Features:
    - Keyword Research: In-depth analysis to target the right audience.
    - On-Page SEO: Optimizing content, structure, and technical elements.
    - Off-Page SEO: Building a strong backlink profile.
    - Technical SEO: Page speed, mobile optimization, fixing broken links.
    - Ongoing Audits and Reports: Regular performance reviews.

    Technologies Used:
    - Google Analytics, SEMrush, Ahrefs
    - Yoast SEO, Rank Math
    - Google PageSpeed Insights, GTMetrix`,
    img: '/SE.jpg'
  },
  { 
    id: 5, 
    title: 'Digital Marketing', 
    description: `Our digital marketing services cover everything from social media management to paid advertising and content strategy. We create multi-channel campaigns that boost your brand's online visibility.

    Key Features:
    - Social Media Marketing: Manage campaigns on platforms like Facebook, Instagram, LinkedIn.
    - PPC Advertising: Develop pay-per-click campaigns.
    - Email Marketing: Use automation tools for personalized email campaigns.
    - Content Marketing: Blog posts, videos, and infographics.
    - Conversion Optimization: Improve digital campaigns for lead generation.

    Technologies Used:
    - Google Ads, Facebook Ads Manager
    - Mailchimp, HubSpot, ActiveCampaign
    - Google Analytics, Mixpanel`,
    img: '/Smm.jpg'
  },
  { 
    id: 6, 
    title: 'UI/UX Design', 
    description: `We specialize in designing intuitive, user-friendly interfaces. Our UI/UX services focus on ensuring your product is functional and aesthetically pleasing.

    Key Features:
    - User Research: In-depth research and user personas.
    - Prototyping: Wireframes and prototypes with tools like Figma and Adobe XD.
    - User Testing: Usability testing to gather insights.
    - Mobile-First Approach: Responsive interfaces for mobile devices.
    - Brand Consistency: Ensure consistent brand identity.

    Technologies Used:
    - Adobe XD, Figma, Sketch
    - InVision, Axure
    - HTML5, CSS3, JavaScript`,
    img: '/design.jpg'
  },
  { 
    id: 7, 
    title: 'Social Media Marketing', 
    description: `Our social media marketing services help your business engage with your target audience. From strategy development to content creation and ad management, we ensure your brand remains top-of-mind.

    Key Features:
    - Social Media Strategy: Grow your presence on platforms like Facebook, Instagram, LinkedIn, and Twitter.
    - Content Creation: Eye-catching visuals, videos, and posts.
    - Paid Advertising: Run targeted ad campaigns.
    - Community Engagement: Interact with your audience through comments and direct messages.
    - Analytics and Reporting: Track performance and optimize campaigns.

    Technologies Used:
    - Facebook Ads Manager, Instagram Ads
    - Buffer, Hootsuite
    - Google Analytics`,
    img: '/Smm.jpg'
  }
];

const ServiceDetail = () => {
  const { id } = useParams();
  const service = services.find(s => s.id === parseInt(id));

  if (!service) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 font-poppins">
        <p className="text-2xl text-gray-600">Service not found. Please check the URL and try again.</p>
      </div>
    );
  }

  const renderDescription = description => {
    return description.split('\n').map((line, index) => {
      // Check if the line starts with a hyphen for bullet points
      if (line.trim().startsWith('-')) {
        return (
          <li key={index} className="lg:text-lg text-base font-light list-disc list-inside">
            {line.replace('-', '').trim()}
          </li>
        );
      } else {
        return (
          <p key={index} className="lg:text-lg text-base font-light">
            {line.trim()}
          </p>
        );
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 font-poppins lg:py-40 py-20">
      <div className="max-w-6xl mx-auto bg-white shadow-2xl rounded-xl overflow-hidden">
        {/* Service Image */}
        <div className="relative">
          <img 
            src={service.img} 
            alt={service.title} 
            className="w-full h-80 object-cover object-center" 
          />
          <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white text-center px-4">
              {service.title}
            </h1>
          </div>
        </div>

        {/* Service Content */}
        <div className="p-8 md:p-12 lg:p-16">
          <Link 
            to="/services" 
            className="flex items-center gap-2 text-blue-600 hover:text-blue-800 mb-8 text-base font-medium transition-colors duration-200"
          >
            <ArrowLeft className="h-4 w-4" />
            Back to Services
          </Link>

          {/* Service Description */}
          <div className="space-y-6 text-gray-700 text-lg leading-relaxed">
            <ul className="list-none space-y-4">
              {renderDescription(service.description)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
