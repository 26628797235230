import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Banner = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="w-full bg-gradient-to-l from-yellow-50 via-gray-100 to-gray-200 overflow-hidden relative">
      {/* Curved border at the top */}
      <svg className="absolute top-0 left-0 w-full" viewBox="0 0 1440 120" preserveAspectRatio="none">
        <path 
          fill="#ffffff" 
          d="M0,56L80,50.7C160,45,320,35,480,40C640,45,800,67,960,72C1120,77,1280,67,1360,61.3L1440,56L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        />
      </svg>

      <div ref={ref} className="container mx-auto flex flex-col md:flex-row w-full min-h-[380px] md:min-h-[400px] lg:min-h-[450px] font-poppins relative z-10 lg:pt-28 pt-10">
        {/* Text Section */}
        <motion.div
          className="w-full md:w-1/2 flex items-center justify-center p-6 md:p-8 lg:p-10  lg:px-24 xl:px-16"
          initial={{ opacity: 0, x: -50 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <div className="text-gray-800 text-center md:text-left max-w-lg">
            <h1 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl font-bold mb-4 text-slate-800 uppercase leading-tight lg:leading-tight font-poppins">
              Amplify Your Brand's Reach
            </h1>
            <p className="text-base sm:text-lg mb-6 text-gray-600 lg:leading-loose font-poppins capitalize">
              Our expert social media marketing strategies will enhance your brand’s visibility, engage your audience, and drive conversions across all major platforms.
            </p>
            {/* Link to the Social Media Marketing Service Detail page */}
            <Link to="/services/7"> {/* Replace '7' with the correct ID */}
  <button className="bg-indigo-700 font-poppins tracking-normal text-white px-6 py-3 rounded-full text-base sm:text-lg hover:bg-indigo-400 transition duration-300">
    Learn More 
  </button>
</Link>

          </div>
        </motion.div>

        {/* Image Section */}
        <motion.div
          className="w-full md:w-1/2 relative overflow-hidden"
          initial={{ opacity: 0, x: 50 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <img
            src="/Smm.jpg"
            alt="Social Media Marketing for Business Growth"
            className="w-full h-64 md:h-full object-cover"
          />
          <div className="absolute inset-0 bg-gray-900 opacity-10"></div>
        </motion.div>
      </div>
    </div>
  );
};

export default Banner;
